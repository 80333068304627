.landing {
    background-image: url("../../assets/landing.png");
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
}

.contact-link:hover {
    color: #667085;
}

.join-button:hover {
    background-color: #5622c4 !important;
    border-color: #5622c4 !important;
}

.email-input:active {
    border-color: rgba(222, 1, 255), 0.8) !important;
    box-shadow:
        0 1px 1px rgba(222, 1, 255), 0.075) inset,
        0 0 8px rgba(222, 1, 255), 0.6) !important;
    outline: 0 none !important;
}

.email-input:focus {
    border-color: rgba(222, 1, 255, 0.8) !important;
    box-shadow:
        0 1px 1px rgba(222, 1, 255, 0.075) inset,
        0 0 8px rgba(222, 1, 255, 0.6) !important;
    outline: 0 none !important;
}
