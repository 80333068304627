html {
  height: 100%;
  background: #fafafa;
}

body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
  height: 100%;
}

.sidebar-tab {
  color: 'inherit';
  text-decoration: none;
}

.unselectable {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */

  -webkit-user-drag: none; /* Prevents dragging of images/divs etc */
  pointer-events: none;
}

.section:before {
  margin-top: -140px;
  height: 140px;
  visibility: hidden;
  pointer-events: none;
  display: block;
  content: '';
}

.violet-gradient {
  background: linear-gradient(180deg, rgba(80, 0, 255, 1) 0%, rgba(255, 34, 193, 1) 100%);
  background-clip: border-box;

  /* clip hackery */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  /* https://davidwalsh.name/css-gradient-text */
}
